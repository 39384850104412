import { Link, navigate } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import lines from "../../images/lines.svg"
import leadLines from "../../images/podcast/leadLines.svg"
import leadsline from "../../images/podcast/leadsline.svg"
import Guest_1 from "../../images/podcast/guest_1.svg"
import Guest_2 from "../../images/podcast/guest_2.svg"
import Guest_3 from "../../images/podcast/guest_3.svg"
import Guest_4 from "../../images/podcast/guest_4.svg"
import * as styles from "./BroadCast.module.scss"

function BroadCast({ strapiData }) {
  const featurePost = strapiData?.filter(e => e?.isFeatured === true)
  const upCommingPost = strapiData?.filter(e => e?.isUpComming === true)

  return (
    <section className={styles.bodyColor}>
      <Container>
        <div className={styles.guestOuter}>
          <div className={styles.guestInner}>
            <p>Featured Guests:</p>
            <img
              src={Guest_1}
              decoding="async"
              loading="lazy"
              alt="guest_icon_1"
            />
            <img
              src={Guest_2}
              decoding="async"
              loading="lazy"
              alt="guest_icon_2"
            />
            <img
              src={Guest_3}
              decoding="async"
              loading="lazy"
              alt="guest_icon_3"
            />
            <img
              src={Guest_4}
              decoding="async"
              loading="lazy"
              alt="guest_icon_4"
            />
          </div>
        </div>
        <div className={styles.rowBody}>
          {featurePost?.map(
            (item, i) =>
              item?.featuredEpisode?.length > 0 && (
                <React.Fragment key={i}>
                  <div className={styles.bg}>
                    <div className={styles.leadLines}>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={leadLines}
                        alt="dotted"
                      />
                    </div>
                    <div className={styles.imageCage}>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={item?.featuredImage?.localFile?.publicURL}
                        alt={item?.featuredImage?.alternativeText}
                      />
                      <img
                        // decoding="async"
                        // loading="lazy"
                        className={styles.playbtn}
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/playbtn_9e33a76c60.png"
                        alt="play button"
                        onClick={() => navigate(`/podcast/${item?.slug}/`)}
                      />
                    </div>
                    <div className={styles.leadsline}>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={leadsline}
                        alt="dotted"
                      />
                    </div>
                    <div className={styles.text}>
                      <h2 className={styles.featureEp}>Feautured Episode</h2>
                      <Link to={`/podcast/${item?.slug}/`}>
                        <h3>{item?.title}</h3>
                      </Link>
                      <div className={styles.showOnHover}>
                        <p className={styles.titleName}>
                          {item?.featuredEpisode[0]?.title}
                        </p>
                        <p>{item?.featuredEpisode[1]?.title}</p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
          <img
            decoding="async"
            loading="lazy"
            alt="dotted"
            src={lines}
            className={styles.bgline}
          />
        </div>
      </Container>
    </section>
  )
}

export default BroadCast
