import { navigate } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import PlayBtn2 from "../../images/carbon_play-filled-alt.svg"
import mike from "../../images/mike.svg"
import PlayBtn from "../../images/playbtn.svg"
import podcastDots from "../../images/podcastDots.png"
import podlines from "../../images/podlines.svg"
import * as styles from "./Stories.module.scss"

function Stories({ filterData }) {
  return (
    <div
      className={`${styles.StartupStories} ${
        filterData.length === 1 ? styles.removeBg : ""
      }`}
    >
      <Container>
        {filterData?.length > 0 ? (
          filterData?.map((item, index) => (
            <div key={index}>
              <Row className={`${styles.Center} gap-30`}>
                <Col lg={2}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={item?.featuredImage?.localFile?.publicURL}
                    alt={"client"}
                    className={styles.imageMain}
                  />
                  <img
                    decoding="async"
                    loading="lazy"
                    className={styles.PlayHover}
                    src={PlayBtn}
                    alt="play"
                    onClick={() => navigate(`/podcast/${item?.slug}/`)}
                  />
                </Col>
                <Col lg={6}>
                  <div className={styles.StoriesText}>
                    <p className={styles.title}>
                      #{item?.podcast_category?.title}
                    </p>
                    <h3>{item?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.overview,
                      }}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  <div>
                    <button
                      onClick={() => navigate(`/podcast/${item?.slug}/`)}
                      className={styles.StoriesBtn}
                    >
                      Listen Now
                      <img
                        decoding="async"
                        loading="lazy"
                        src={PlayBtn2}
                        alt="PlayBtn2"
                        style={{ paddingLeft: "7px" }}
                      />
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <div className="text-center">No Data Found</div>
        )}
        <div className={styles.storiesDots}>
          <img decoding="async" loading="lazy" src={podcastDots} alt="dots" />
        </div>
        <div className={styles.mike}>
          <img decoding="async" loading="lazy" src={mike} alt="mike" />
        </div>
        <div className={styles.podlines}>
          <img decoding="async" loading="lazy" src={podlines} alt="dotted" />
        </div>
      </Container>
    </div>
  )
}
export default Stories
