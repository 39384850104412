// extracted by mini-css-extract-plugin
export var Cards = "Popular-module--Cards--0a88f";
export var CenterBox = "Popular-module--CenterBox--aadeb";
export var PlayBtn2 = "Popular-module--PlayBtn2--69a49";
export var PopularImg = "Popular-module--PopularImg--01472";
export var StoriesBtn = "Popular-module--StoriesBtn--393ec";
export var bg = "Popular-module--bg--dd018";
export var heading = "Popular-module--heading--e4526";
export var imgDots = "Popular-module--imgDots--01492";
export var lines2 = "Popular-module--lines2--6bd68";
export var mike2 = "Popular-module--mike2--c1b16";
export var mike3 = "Popular-module--mike3--378f9";
export var mike4 = "Popular-module--mike4--e20a4";
export var mostPopulr = "Popular-module--mostPopulr--f0989";
export var storiesDots = "Popular-module--storiesDots--ff1cb";