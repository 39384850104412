// extracted by mini-css-extract-plugin
export var Center = "Stories-module--Center--a0866";
export var PlayHover = "Stories-module--PlayHover--dffa0";
export var StartupStories = "Stories-module--StartupStories--f81a3";
export var StoriesBtn = "Stories-module--StoriesBtn--826eb";
export var StoriesText = "Stories-module--StoriesText--4e3f4";
export var imageMain = "Stories-module--imageMain--adc05";
export var mike = "Stories-module--mike--b9fac";
export var podlines = "Stories-module--podlines--05ace";
export var removeBg = "Stories-module--removeBg--3a9ef";
export var storiesDots = "Stories-module--storiesDots--8d6f6";
export var title = "Stories-module--title--0b134";