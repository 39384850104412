import React from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/podcast/Banner"
import Broadcast from "../components/podcast/BroadCast"
import Popular from "../components/podcast/Popular"
import StartUp from "../components/podcast/StartUp"
import * as styles from "../components/saas-files/blogPost.module.scss"
import MainLayout from "../layouts/MainLayout"

function PodCast({ pageContext }) {
  const podcasts = pageContext?.allPodcast

  return (
    <MainLayout podcast={true}>
      <div className={styles.parrent}>
        <Banner />
        <Broadcast strapiData={podcasts} />
        <StartUp strapiData={podcasts} />
        <Popular strapiData={podcasts} />
      </div>
    </MainLayout>
  )
}

export default PodCast

export const Head = () => {
  return (
    <SEORevamp
      title="InvoTalks Powered by InvoZone"
      description="Listen to our tech rich discussion latest trends & startups. Every week. We are proudly powered by Invozone, a leading software & Web app development company."
      image="https://invozone-backend.s3.amazonaws.com/podcastnew_ff89ddaf72.webp"
    />
  )
}
