import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useMemo, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"
import Sonnet from "../../components/podcast/Stories"
import Startup from "../../images/Startup.svg"
import * as styles from "./StartUp.module.scss"
import "./startUp.scss"

function StartUp({ strapiData }) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPodcategory {
        nodes {
          slug
          title
          # childStrapiPodcategoryDescriptionTextnode {
          #   description
          # }
        }
      }
    }
  `)

  const StartupData = data?.allStrapiPodcategory?.nodes

  const [currentCat, setCurrentCat] = useState("all")
  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const length = 5

  var filterData = useMemo(
    () =>
      strapiData.filter(e =>
        currentCat === "all" && !e.isUpComming
          ? strapiData
          : e.podcast_category?.title === currentCat
      ),
    [strapiData, currentCat]
  )

  useEffect(() => {
    setList([...filterData?.slice(0, length)])
  }, [filterData])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < filterData?.length
      const nextResults = isMore
        ? filterData?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < filterData?.length
    setHasMore(isMore)
  }, [list, filterData])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  return (
    <div className="podcastTabs">
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey={"all"}>
          <Col>
            <Nav className={styles.Tabbing}>
              {StartupData?.map((e, i) => (
                <Nav.Item key={i} className="startup-nav-item">
                  <Nav.Link
                    eventKey={e?.title}
                    onClick={() => setCurrentCat(e?.title)}
                  >
                    <div className={styles.text}>
                      <h2>{e?.title}</h2>
                      <img
                        decoding="async"
                        loading="lazy"
                        src={Startup}
                        alt="startup"
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>
          <Col>
            <Tab.Content>
              {currentCat === "all" ? (
                <Tab.Pane eventKey="all">
                  <Sonnet title={"all"} filterData={list} />
                  {hasMore && (
                    <div className={styles.loadBtn}>
                      <button
                        className="btn_white2_border"
                        onClick={handleLoadMore}
                      >
                        Load more
                      </button>
                    </div>
                  )}
                </Tab.Pane>
              ) : (
                StartupData?.map((e, i) => (
                  <Tab.Pane key={i} eventKey={e?.title}>
                    <Sonnet title={e?.title} filterData={list} />
                    {hasMore && (
                      <div className={styles.loadBtn}>
                        <button
                          className="btn_white2_border"
                          onClick={handleLoadMore}
                        >
                          Load more
                        </button>
                      </div>
                    )}
                  </Tab.Pane>
                ))
              )}
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Container>
    </div>
  )
}

export default StartUp
