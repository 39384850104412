import { navigate } from "gatsby"
import React, { useMemo } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import PlayBtn2 from "../../images/carbon_play-filled-alt2.svg"
import lines2 from "../../images/lines2.svg"
import mike3 from "../../images/mike 3.svg"
import mike2 from "../../images/mike2.svg"
import mike4 from "../../images/mike3.svg"
import podcastDots from "../../images/podcastDots.png"
import imgDots from "../../images/podimgdots.svg"
import { stringTruncate } from "../../utils"
import * as styles from "./Popular.module.scss"

function Popular({ strapiData }) {
  const filterData = useMemo(
    () => strapiData.filter(e => !e.isUpComming && e),
    [strapiData]
  )

  return (
    <section>
      <Container>
        <div className={styles.heading}>
          <h2>Most Popular</h2>
        </div>
        <Row className={`${styles.CenterBox} align-items-end`}>
          {filterData?.map(
            (item, index) =>
              index < 3 && (
                <Col xl={4} lg={12} key={index} style={{ display: "flex" }}>
                  <div className={styles.mostPopulr}>
                    <div className={styles.bg}>
                      <div>
                        <div
                          className={styles.Cards}
                          style={{ display: "flex" }}
                        >
                          <div className={styles.imgDots}>
                            <img
                              decoding="async"
                              loading="lazy"
                              src={imgDots}
                              alt="dots"
                            />
                          </div>
                          <img
                            decoding="async"
                            loading="lazy"
                            className={styles.PopularImg}
                            src={item?.featuredImage?.localFile?.publicURL}
                            alt={
                              item?.featuredImage?.alternativeText ||
                              item?.title
                            }
                          />
                          <h3>{stringTruncate(item?.title, 50)}</h3>
                        </div>
                        <button
                          onClick={() => navigate(`/podcast/${item?.slug}/`)}
                          className={styles.StoriesBtn}
                        >
                          Listen Now
                          <img
                            decoding="async"
                            loading="lazy"
                            src={PlayBtn2}
                            alt="play Button"
                            className={styles.PlayBtn2}
                            style={{ paddingLeft: "7px" }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              )
          )}
        </Row>
        <div className={styles.storiesDots}>
          <img decoding="async" loading="lazy" src={podcastDots} alt="dots" />
        </div>
        <div className={styles.mike2}>
          <img decoding="async" loading="lazy" src={mike2} alt="mike" />
        </div>
        <div className={styles.mike3}>
          <img decoding="async" loading="lazy" src={mike3} alt="mike" />
        </div>
        <div className={styles.lines2}>
          <img decoding="async" loading="lazy" src={lines2} alt="mike" />
        </div>
        <div className={styles.mike4}>
          <img decoding="async" loading="lazy" src={mike4} alt="mike" />
        </div>
      </Container>
    </section>
  )
}

export default Popular
